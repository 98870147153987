<template>
  <v-list-item-avatar v-on="{ ...$listeners }" v-if="!getHideIcons"
    ><slot v-bind="$attrs"></slot>
  </v-list-item-avatar>
</template>
<script>
import { getHideIcons } from "common/utils/icons.js";
export default {
  name: "ListIcon",
  props: {},
  computed: {
    getHideIcons,
  },
};
</script>
